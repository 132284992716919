<template>
    <div ref="formInput" class="form-input">
        <label :for="id">
            <slot name="label">
                {{ $t(label || '') }}
            </slot>
            <span v-if="label" class="after-label">
                <slot name="after-label"/>
            </span>
        </label>

        <slot/>
    </div>
</template>

<script setup>
import {ref} from 'vue';

const props     = defineProps({
    label: {
        type:     String,
        required: false,
    },
    id:    {
        type:     String,
        required: true,
    },
});
const formInput = ref(null);
defineExpose({ref: formInput});
</script>

<style lang="scss">
.form-input {
    text-align: left;
    display: flex;
    flex-flow: column;
    gap: 1rem;

    &.error {
        > .text-input, .file-input, .time-input {
            input {
                border-color: var(--corebox-alert);

                &::placeholder {
                    color: var(--corebox-alert);
                }
            }
        }

        > .corebox-select {
            .corebox-select_selected {
                color: var(--corebox-alert);
                border-color: var(--corebox-alert);
            }
        }
    }

    > * {
        width: 100%;
    }

    label {
        display: flex;
        gap: 1rem;
        line-break: anywhere;

        &:empty {
            display: none;
        }

        .after-label {
            &:empty {
                display: none;
            }
        }
    }

    &.disabled-inputs {
        input,
        select,
        .corebox-select,
        .corebox-select_selected {
            cursor: not-allowed;
            background: var(--secondary);
            color: var(--text-secondary);
        }
    }
}
</style>
